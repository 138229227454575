import Home from '../components/views/home/Home.vue'
import Programs from '../components/views/programs/Programs.vue'
import Search from '../components/views/search/Search.vue'
import MyYoga from '../components/views/myyoga/MyYoga.vue'
import Favorites from '../components/views/favorites/Favorites.vue'
import History from '../components/views/history/History.vue'
import Lives from '../components/views/lives/Lives.vue'
import RegisterToLive from '../components/views/lives/RegisterToLive.vue'
import SingleProgram from '../components/views/SingleProgram.vue'
import SingleCourse from '../components/views/SingleCourse'
import Settings from '../components/views/Settings'
import Login from '../components/views/forms/Login.vue'
import Forgot from '../components/views/forms/Forgot.vue'
import PaymentCheckout from '../components/views/PaymentCheckout'
import PaymentSuccess from '../components/views/PaymentSuccess'
import PaymentGiftCard from '../components/views/PaymentGiftCard'
import GiftCardSuccess from '../components/views/GiftCardSuccess'
import Code from '../components/views/Code'
import UseGiftCard from '../components/views/UseGiftCard'
import Conditions from '../components/views/Conditions.vue'
import Favorite from '../components/views/Favorite.vue'
import Confidentialite from '../components/views/Confidentialite.vue'
import Privacy from '../components/views/Privacy.vue'
import Terms from '../components/views/Terms.vue'
import LiveScheduler from '../components/views/LiveScheduler.vue'

export default [
    { path:'/', component: Home },
    {
      path:'/programs',
      component: Programs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/search',
      component: Search,
      meta: {
        requiresAuth: true
      }
    },
    {
        path:'/myyoga',
        component: MyYoga,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/favorites',
        component: Favorites,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/history',
        component: History,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/lives',
        component: Lives,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/registerToLive/:appointmentTypeID/:datetime',
        component: RegisterToLive,
        meta: {
            requiresAuth: true
        }
    },
    {
      path:'/program/:id',
      component: SingleProgram,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/course/:id',
      component: SingleCourse,
      meta: {
        requiresAuth: true,
        requiresSubscription: true
      }
    },
    {
      path:'/settings',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    { path:'/login', component: Login },
    { path:'/forgotpassword', component: Forgot },
    { path:'/favorite', component: Favorite },
    { path:'/code', component: Code },
    { path:'/conditions', component: Conditions },
    { path:'/confidentialite', component: Confidentialite },
    { path:'/privacy', component: Privacy },
    { path:'/terms', component: Terms },
    { path:'/liveScheduler', component: LiveScheduler },
    { path: '/:language/paymentSuccess', component: PaymentSuccess },
    {
      path: '/:language/payment',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: false
      }
    },
    {
      path: '/:language/payment/:email',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: false
      }
    },
    {
      path: '/:language/promo',
      name: 'promo6',
      component: PaymentCheckout,
      props: {
        is6Months: true,
        isTimeLimited: false
      }
    },
    {
      path: '/:language/promo/:email',
      name: 'promo6WithEmail',
      component: PaymentCheckout,
      props: {
        is6Months: true,
        isTimeLimited: false
      }
    },
    {
      path: '/:language/promoYearly',
      name: 'promoYearly39',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: true
      }
    },
    {
      path: '/:language/promoYearly/:email',
      name: 'promoYearly39WithEmail',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: true
      }
    },
    {
      path: '/:language/promoYearly29',
      name: 'promoYearly29',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: true,
        is29: true
      }
    },
    {
      path: '/:language/promoYearly29/:email',
      name: 'promoYearly29WithEmail',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: true,
        is29: true
      }
    },
    {
      path: '/:language/promo29',
      name: 'promo29',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: false,
        is29: true
      }
    },
    {
      path: '/:language/promo29/:email',
      name: 'promo29WithEmail',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isTimeLimited: false,
        is29: true
      }
    },
    {
      path: '/:language/welcomeback',
      name: 'welcomeBack',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isWelcomeBack: true,
        isTimeLimited: false,
        is20: true
      }
    },
    {
      path: '/:language/welcomeback/:email',
      name: 'welcomeBackWithEmail',
      component: PaymentCheckout,
      props: {
        is6Months: false,
        isWelcomeBack: true,
        isTimeLimited: false,
        is20: true
      }
    },
    {
      path: '/:language/payGiftCard',
      name: 'payGiftCard',
      component: PaymentGiftCard
    },
    { path: '/:language/giftCardSuccess', component: GiftCardSuccess },
    {
      path: '/:language/useGiftCard',
      name: 'useGiftCard',
      component: UseGiftCard
    },
    {
      path: '/offrir',
      redirect: '/fr/payGiftCard'
    },
    {
      path: '/offerta',
      redirect: '/it/payGiftCard'
    },
    {
      path: '/cadeau',
      redirect: '/fr/useGiftCard'
    },
    {
      path: '/fr/cadeau',
      redirect: '/fr/useGiftCard'
    },
    {
      path: '/regalo',
      redirect: '/it/useGiftCard'
    },
    {
      path: '/it/regalo',
      redirect: '/it/useGiftCard'
    },
    {
      path: '/gift',
      redirect: '/en/useGiftCard'
    },
    {
      path: '/en/gift',
      redirect: '/en/useGiftCard'
    },
    {
      path: '/geschenk',
      redirect: '/de/useGiftCard'
    },
    {
      path: '/de/geschenk',
      redirect: '/de/useGiftCard'
    },
    {
      path: '/code',
      redirect: '/fr/code'
    },
    {
      path: '/:language/code',
      name: 'useCode',
      component: Code
    },
    {
      path: '/404',
      component: Home
    },
    {
      path: '*',
      component: Home
    }
]

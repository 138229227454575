import firebase from 'firebase/app'
import { language } from '@/common/language'

const apiURL = "https://us-central1-gotta-joga.cloudfunctions.net/api"
const authenticatedAPIURL = "https://us-central1-gotta-joga.cloudfunctions.net/api2"
const webhooksURL = "https://us-central1-gotta-joga.cloudfunctions.net/webhooks"

async function get(path) {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const locale = language()
  const res = await fetch(authenticatedAPIURL + "/" + path + "?lang=" + locale, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
  return res.json()
}

async function postAuthenticated(path, body) {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const locale = language()
  const res = await fetch(authenticatedAPIURL + "/" + path+ "?lang=" + locale, {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    }),
    body: JSON.stringify(body)
  })
  return res.json()
}

async function post(url, path, body) {
  const res = await fetch(url + "/" + path, {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(body)
  })
  return res.json()
}

export async function getProgram(id) {
  return await get("programs/" + id)
}

export async function getCourse(id) {
  return await get("classes/" + id)
}

export async function getCollections() {
  return await get("collections")
}

export async function getFilters() {
  return await get("filters")
}

export async function search(searchQuery, filterQuery, sortQuery) {
  return await postAuthenticated("search", {full_text_search: searchQuery, filter: filterQuery, sort: sortQuery})
}

export async function getFavorites() {
  return await get("my/favorites")
}

export async function toggleFavorite(id) {
  return await postAuthenticated("toggleFavorite/" + id)
}

export async function addYogaClassCompletion(id) {
  return await postAuthenticated("addYogaClassCompletion", {id: id, completion: 1})
}

export async function addLiveClassCompletion(dateString, duration, teacher, style, title, isFree, level, imageURL, replayURL) {
  return await postAuthenticated("addLiveClassCompletion", {
    dateString: dateString,
    duration: duration,
    teacher: teacher,
    style: style,
    title: title,
    isFree: isFree,
    level: level,
    imageURL: imageURL,
    replayURL: replayURL
  })
}

export async function getHistory() {
  return await get("my/recent")
}

export async function getMyYoga() {
  return await get("my/yoga")
}

export async function postCode(code, email) {
  return await post(apiURL, "useCode", {
    code: code,
    email: email
  })
}

export async function postUseGiftCard(code, email) {
  return await post(apiURL, "useGiftCard", {
    code: code,
    email: email
  })
}

export async function postGiftCard(numberOf1Year, numberOf6Months, email, language) {
  return await post(apiURL, "checkoutGiftCard", {
    email: email,
    language: language,
    numberOf1Year: numberOf1Year,
    numberOf6Months: numberOf6Months
  })
}

export async function postCharge(source, email, subscriptionId, couponId, webPageId) {
  return await post(apiURL, "charge", {
    source: source,
    email: email,
    subscriptionId: subscriptionId,
    couponId: couponId,
    webPageId: webPageId
  })
}

export async function postChargeCheckout(email, subscriptionId, couponId, webPageId) {
  return await post(apiURL, "checkoutPayment", {
    email: email,
    subscriptionId: subscriptionId,
    couponId: couponId,
    webPageId: webPageId
  })
}

export async function deleteAccount() {
  const token = await firebase.auth().currentUser.getIdToken(true)
  await fetch(webhooksURL + "/delete_account_and_data", {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
}

export async function postSubscriptionStatus(uid) {
  return await post(webhooksURL, "update_subscription_status", {
    uid: uid
  })
}

export async function createStripeCustomerPortalSession(customerId) {
  return await post(webhooksURL, "create_customer_portal_session", {
    customer_id: customerId
  })
}

export async function getAcuityClient() {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const res = await fetch(webhooksURL + "/get_acuity_client", {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
  return res.json()
}

export async function searchAcuityClient() {
  const email = firebase.auth().currentUser.email
  if (email) {
    const token = await firebase.auth().currentUser.getIdToken(true)
    const res = await fetch(webhooksURL + "/search_client?search=" + email, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        "Authorization": "Bearer " + token
      })
    })
    const data = await res.json()
    if (Array.isArray(data) && data.length > 0) {
      return data[0];
    } else {
      return null
    }
  } else {
    return null
  }
}

export async function getLives(acuityEmail) {
  const token = await firebase.auth().currentUser.getIdToken(true)
  let email = ""
  if (acuityEmail) {
    email = "?email=" + acuityEmail
  }
  const res = await fetch(webhooksURL + "/get_live_classes" + email, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token
    })
  })
  return res.json()
}

export async function registerToLive(email, appointmentTypeID, datetime, firstName, lastName, phone, timezone) {
  const token = await firebase.auth().currentUser.getIdToken(true)
  const body = {
    email: email,
    appointmentTypeID: appointmentTypeID,
    datetime: datetime,
    firstName: firstName,
    lastName: lastName,
    smsOptIn: false
  }
  if (phone !== null) {
    body.phone = phone
    body.smsOptIn = true
  }
  if (timezone !== null) {
    body.timezone = timezone
  }
  const res = await fetch(webhooksURL + "/register", {
    method: 'post',
    mode: 'cors',
    headers: new Headers({
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(body)
  })
  return res.json()
}
import {VueTelInput} from 'vue-tel-input';
import { language } from '@/common/language'
import {getLives, registerToLive, addLiveClassCompletion, getAcuityClient, searchAcuityClient} from "@/api/requests";

export default {
    name: "RegisterToLive",
    components: {
        VueTelInput,
        BaseBtn: () => import("@/components/base/Btn"),
    },
    data() {
        return {
            mounting: true,
            loading: false,
            firstname: '',
            lastname: '',
            email: '',
            phone_number: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            appointmentTypeID: this.$route.params.appointmentTypeID,
            datetime: this.$route.params.datetime,
            registerError: null,
            success: false
        }
    },
    computed: {
        country() {
            const code = language()
            if (code === "fr" || code === "de" || code === "it" || code === "fi") {
                return code
            } else {
                return "gb"
            }
        }
    },
    mounted() {
        this.loadAcuityClient()
    },
    methods: {
        async loadAcuityClient() {
            this.mounting = true
            const client = await getAcuityClient()
            if (client) {
                if (client.email) {
                    this.email = client.email
                }
                if (client.firstName) {
                    this.firstname = client.firstName
                }
                if (client.lastName) {
                    this.lastname = client.lastName
                }
                if (client.phone) {
                    this.phone_number = client.phone
                }
                if (client.timezone) {
                    this.timezone = client.timezone
                }
            } else {
                const clientWithUserEmail = await searchAcuityClient()
                if (clientWithUserEmail) {
                    if (clientWithUserEmail.email) {
                        this.email = clientWithUserEmail.email
                    }
                    if (clientWithUserEmail.firstName) {
                        this.firstname = clientWithUserEmail.firstName
                    }
                    if (clientWithUserEmail.lastName) {
                        this.lastname = clientWithUserEmail.lastName
                    }
                    if (clientWithUserEmail.phone) {
                        this.phone_number = clientWithUserEmail.phone
                    }
                    if (clientWithUserEmail.timezone) {
                        this.timezone = clientWithUserEmail.timezone
                    }
                }
            }
            this.mounting = false
        },
        async register() {
            this.registerError = null
            this.loading = true

            const firstnameError = this.validateField(this.firstname)
            if (firstnameError !== null) {
                this.registerError = this.$i18n.t("FirstnameIsEmpty")
                this.loading = false
                return
            }

            const lastnameError = this.validateField(this.lastname)
            if (lastnameError !== null) {
                this.registerError = this.$i18n.t("LastnameIsEmpty")
                this.loading = false
                return
            }

            const emailError = this.validateField(this.email)
            if (emailError !== null) {
                this.registerError = this.$i18n.t("EmailIsEmpty")
                this.loading = false
                return
            }

            // Don’t validate the email, let acuity do it. Same for phone number and timezone.

            const res = await registerToLive(this.email, this.appointmentTypeID, this.datetime, this.firstname, this.lastname, this.phone_number, this.timezone)
            let errorCode = res.error
            let errorMsg = null
            if (errorCode === "invalid_email") {
                errorMsg = "Invalid email"
            } else if (errorCode === "not_available") {
                errorMsg = "The live class is not an available"
            } else if (errorCode === "not_available_min_hours_in_advance") {
                errorMsg = "Not registered far enough in advance"
            } else if (errorCode) {
                errorMsg = errorCode
            }
            if (errorMsg !== null) {
                this.registerError = this.$i18n.t(errorMsg)
                this.loading = false
                return
            }

            const lives = await getLives(this.email)
            const matchingLive = lives.find(live => (live.appointmentTypeID+"" === this.appointmentTypeID) && (live.time === this.datetime))
            if (matchingLive) {
                if (matchingLive.joinURL) {
                    const date = new Date(matchingLive.time);
                    const dateString = date.toISOString()
                    const duration = matchingLive.duration
                    const teacher = matchingLive.category
                    const style = matchingLive.style
                    const title = matchingLive.name
                    const isFree = matchingLive.price <= 0
                    const level = matchingLive.level
                    const imageURL = matchingLive.image
                    const replayURL = matchingLive.replayURL
                    await addLiveClassCompletion(dateString, duration, teacher, style, title, isFree, level, imageURL, replayURL)

                    this.success = true
                    this.loading = false
                    return
                }
            }

            this.registerError = this.$i18n.t("Registration error")
            this.loading = false
        },
        validateField(text) {
            if ((text === null) || (text === "")) {
                return this.$i18n.t("FirstnameIsEmpty")
            }
            return null
        }
    }
};

import { getAcuityClient, getLives, searchAcuityClient } from "@/api/requests";
import { isSubscribed } from "@/utils/subscribed";
import { subscribeURL } from '@/common/language'

export default {
    name: "History",
    components: {
        BaseSection: () => import("@/components/base/Section"),
        BaseSectionHeading: () => import("@/components/base/SectionHeading"),
        BaseBody: () => import("@/components/base/Body"),
        BaseCoursePreview: () => import("@/components/base/LivePreview"),
    },

    data() {
        return {
            classes: [],
            loading: false,
            livesTitle: this.$i18n.t("Lives"),
            subscribed: false,
        };
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            this.loading = true
            this.subscribed = await isSubscribed()
            const client = await getAcuityClient()
            let acuityEmail = null
            if (client) {
                if (client.email) {
                    acuityEmail = client.email
                }
            } else {
                const clientWithUserEmail = await searchAcuityClient()
                if (clientWithUserEmail) {
                    acuityEmail = clientWithUserEmail.email
                }
            }
            const res = await getLives(acuityEmail)
            this.classes = res.reverse().map((c) => ({
                ...c,
                pic_url: c.image,
                duration: c.duration * 60,
                name_style: c.style,
                name_teacher: c.category,
                title: c.name,
                isFree: c.price <= 0
            }))
            this.loading = false
        },
        subscribeURL() {
            return subscribeURL()
        }
    }
};
